@use '@angular/material' as mat;

@mixin theme-reset($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .bg-primary {
    background-color: mat.get-color-from-palette($primary, 700);
  }
  .bg-accent {
    background-color: mat.get-color-from-palette($accent, 300);
  }
  .bg-warn {
    background-color: mat.get-color-from-palette($warn, A400);
  }

  .text-muted {
    color: mat.get-color-from-palette($foreground, secondary-text) !important;
  }

  .secondary-color {
    color: mat.get-color-from-palette($primary, 100);
  }

  .mat-snack-bar-container.success {
    background: #388E3C;
  }
  .mat-snack-bar-container.error {
    background: #E53935;
  }

  .primary-text {
    color: mat.get-color-from-palette($primary);
  }

  .active-link {
    background-color: mat.get-color-from-palette($primary);
    color: #fff;
  }
  .horizontal-active-link,
  .app-dropdown .mat-menu-item.horizontal-active-link {
    color: mat.get-color-from-palette($primary) !important;
  }


}
