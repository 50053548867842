@each $breakpoint in map-keys($grid-breakpoints) {

  @include media-breakpoint-up($breakpoint) {

    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {

      @for $index from 0 through 32 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}t#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}r#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }

        .#{$abbrev}b#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

  }
}
