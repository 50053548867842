/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import 'src/app/core/scss/core';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-page {
  height: 100%;
  display: flex;
}

.spinner-container {
  position: fixed;
}

.grid-link {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}

.edit-content {
  max-width: 1300px;
}

.bar-buttons {
  min-width: 75px;
}

.date-picker-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.mat-tooltip.note-tooltip {
  font-size: 22px;
}

.global-edit-form-dialog {

  @include media-breakpoint('xs') {
    width: 100%;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .dialog-content-wrapper {
    width: 100%;
    max-height: 95vh; // 85vh
    display: flex;
    flex-direction: column;
  }
}

.page-title-row {
  background: #fff;
  padding: 8px 12px;

  @include mat.elevation(1);

  .search-wrapper {
    border: 1px solid #ccc;
    display: inline-block;
    position: relative;

    input {
      padding: 8px;
      padding-right: 30px;
    }

    mat-icon {
      padding: 4px;
    }

    button {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 1;
    }

    input,
    button {
      background-color: transparent;
      border: 0;
      outline: none;
    }

  }

}

.brcolor {
  background-color: red;
}

.bbolor {
  background-color: blue;
}

.bgcolor {
  background-color: green;
}

.mat-drawer-container .mat-drawer-content {
  overflow: visible;
  z-index: 2;
}