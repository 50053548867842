$font-family: Roboto, sans-serif;
$theme-max-width: 1300px;
$height-calendar-item-container: 48px;
$height-calendar-item-container-condensed: 24px;
$calendar-item-gap: 6px;
$print-page-height: 11.7in;
$print-page-width: 8.3in;
$print-page-zoom: 1;
$mdc-linear-progress-active-indicator-color: #3C6D70;

:root {
    --height-calendar-item-container: #{$height-calendar-item-container};
    --calendar-item-gap: #{$calendar-item-gap};
    --height-calendar-item-container-condensed: #{$height-calendar-item-container-condensed};
    --print-page-height: #{$print-page-height};
    --print-page-width: #{$print-page-width};
    --print-page-zoom: #{$print-page-zoom};
    --mdc-linear-progress-active-indicator-color: #{$mdc-linear-progress-active-indicator-color};
}