.text-green {
  color: green;
}

.text-howzit {
  color: #619b42;
}

.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

.text-italic {
  font-style: italic;
}

.text-semibold {
  font-weight: 600;
}

.text-bold,
strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
@for $weight from 1 through 9 {

  .font-weight-#{$weight * 100} {
    font-weight: #{$weight * 100};
  }
}
@for $size from 1 through 60 {

  .font-size-#{$size * 2} {
    font-size: #{$size * 2}px;
  }
}
.text-boxed {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
}
.text-nowrap {
  white-space: nowrap;
}
// }
